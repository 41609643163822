import axios from './index'

export default {
  getCustomer(customerId) {
    return axios.get('/customer/getCustomerForEdit?id=' + customerId)
      .then(response => {
        const modelResponse = response.data;

        modelResponse.enableSms = modelResponse.enableSms == 0 ? 2 : 1;
        modelResponse.enableEmail = modelResponse.enableEmail == 0 ? 2 : 1;
        modelResponse.phoneObject = {
          phoneSalt: response.data.phoneSalt,
          internationalFormat: response.data.phone,
          phoneCountryCode: response.data.phoneCountryCode,
          phoneRegionCode: response.data.phoneRegionCode
        }

        if (modelResponse.customer_Tags && modelResponse.customer_Tags.length > 0) {
          let TagsTextArray = [];

          for (var i = 0; i < response.data.customer_Tags.length; i++) {
            TagsTextArray.push({
              id: response.data.customer_Tags[i].tagID,
              text: response.data.customer_Tags[i].tag.name
            });
          }

          modelResponse.customer_Tags = TagsTextArray;
        }

        return modelResponse;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getTags(tag) {
    return axios.get('/customer/getTags?keyword=' + tag)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getJobs(tag) {
    return axios.get('/customer/getJobs?keyword=' + tag)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getGenders() {
    return axios.get('/data/common/gender')
      .then(response => {
        const translatedResponse = response.data.map(gender => {
          gender.name = window.vm.$t(gender.name);
          return gender;
        });
        return translatedResponse;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getMarialStatus() {
    return axios.get('/data/shared/marialStatus')
      .then(response => {
        const translatedResponse = response.data.map(marialStatus => {
          marialStatus.name = window.vm.$t(marialStatus.name);
          return marialStatus;
        });
        return translatedResponse;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerMenuItems() {
    return axios.get('/api/vegaCommon/getCustomerMenu')
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerInfo(customerId) {
    return axios.get(`/customer/getCustomerInfo?id=${customerId}`)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerTags(customerId) {
    return axios.get(`/customer/getCustomerTags?customerId=${customerId}`)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerSetting() {
    return axios.get('/management/GetCustomerSettings')
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerProfilePicture(customerId) {
    return axios.get('/Multimedia/GetCustomerProfilePicture?customerId=' + customerId)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerHistory(customerId) {
    return axios.get('/Customer/GetCustomerHistory?id=' + customerId)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerNotification(customerId) {
    return axios.get('/Customer/GetCustomerFeed?id=' + customerId)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerTypeList(customerTypeID) {
    return axios.get('/data/common/customerType?id' + customerTypeID)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  postCustomerForm(url, model) {
    return axios.post(url, model)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  postCustomerFormFast(model) {
    return axios.post("/Customer/Fast", model)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  checkPhoneNumber(number) {
    return axios.post('/customer/phoneNumberIsExist', number)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  postCustomerImport(form) {
    return axios.post('/customer/getHeaderColumnsFromExcel', form)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  postExcel(form) {
    return axios.post('/customer/importFromExcel', form)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
}
