<template>
  <ValidationProvider v-slot="{ errors }" rules="required" :name="$t('Phone')">
    <div class="input-group">
      <div class="input-group-prepend">
        <img class="img-thumbnail" width="55" :src="flagPath" style="height: calc(1.4em + 1.2rem);" />
      </div>

      <input :maxlength="maxLenght"
             :disabled="isDisabledInput" class="form-control" v-bind:class="(errors.length > 0 ? ' is-invalid' : '')" type="tel" v-model="phoneNumber.internationalFormat" />

      <div v-if="errors[0]" class="invalid-feedback">
        <span>{{ $t('ValidationMessage.CannotBeEmpty') }}</span>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
  import { parsePhoneNumberFromString } from 'libphonenumber-js'
  import customerService from '../../services/customer'
  import '../../plugins/sweetalert'

  export default {
    name: "PhoneNumber",
    props: {
      value: { phoneSalt: String, internationalFormat: String, phoneCountryCode: String, phoneRegionCode: String, phoneNumberIsExists: Boolean },
      checkLeads: Boolean,
      objectId: String,
      isDisabledInput: Boolean,
      maxLenght: Number,
      useCheckPhoneNumber: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        phoneNumber: {},
        phoneObject: {},
        isValid: false,
        phoneNumberIsExists: false,
        customers: [],
        oldNumber: '',
        phoneLastTwoCharacters: ''
      };
    },
    methods: {
    },
    watch: {
      'phoneNumber.internationalFormat': function (val) {
        let $this = this;
        let phone = false;

        if (!val || val.length == 0) {
          $this.value.isPhoneValid = false;
        }
        phone = Array.from(val).every(z => z == "1")

        if (phone) {
          if (val && val.length == 10) {
            $this.isValid = true;
            $this.value.phoneSalt = val;
            $this.value.internationalFormat = val;
            $this.value.phoneCountryCode = null;
            $this.value.phoneRegionCode = null;
            $this.value.isPhoneValid = $this.isValid;
          }
          else {
            $this.phoneObject = {};
            $this.isValid = false;
          }
        } else {
          const libphonenumberObject = parsePhoneNumberFromString(val, $this.currentCountryCode);
          if (libphonenumberObject) {
            $this.phoneObject = {
              phone: libphonenumberObject.number,
              country: libphonenumberObject.country,
              internationalFormat: libphonenumberObject.formatInternational(),
              nationalFormat: libphonenumberObject.formatNational(),
              countryCallingCode: libphonenumberObject.countryCallingCode
            };
            $this.isValid = libphonenumberObject.isValid();
            $this.value.phoneSalt = $this.phoneObject.phone;
            $this.value.internationalFormat = $this.phoneObject.internationalFormat;
            $this.value.phoneCountryCode = $this.phoneObject.countryCallingCode;
            $this.value.phoneRegionCode = $this.phoneObject.country;
            $this.value.isPhoneValid = $this.isValid;
            if ($this.isValid && $this.useCheckPhoneNumber) {
              customerService.checkPhoneNumber($this.value)
                .then(response => {
                  $this.phoneNumberIsExists = response.isExists;
                  $this.customers = response.customerBasicInfo;
                }).finally(() => {
                  if (($this.phoneNumberIsExists && !$this.objectId) || ($this.objectId && $this.oldNumber.replace(/\s/g, '') != $this.value.internationalFormat.replace(/\s/g, '') && $this.oldNumber.replace(/\s/g, '') != $this.value.phone)) {
                    $this.value.phoneNumberIsExists = $this.phoneNumberIsExists;
                    if ($this.customers.length > 0) {
                      let alertTitle = $this.$t("DuplicatePhoneNumber.CustomerTitle");
                      let alertDescription = $this.$t("DuplicatePhoneNumber.Description");
                      let cancelButton = $this.$t("Close");
                      let _html = '';
                      if ($this.$route.name != 'selfRegister') {
                        _html += "<div class='row' style='margin: 0px !important;'>";
                        _html += "<div class='col-md-12' style='margin-bottom: 10px !important'>";
                        _html += alertDescription;
                        _html += "</div>";
                        $this.customers.forEach((item, index) => {
                          if (index == 0) {
                            _html += "<div class='col-md-12'>";
                            _html += '<table class="table" style="margin-bottom: 0px !important">' +
                              '<tr><th style="font-size: medium;">' + $this.$t("Customer") +
                              '</th><th style="font-size: medium;">' + $this.$t("Age") +
                              '</th><th style="font-size: medium;">' + $this.$t("Country") + '</tr>';

                          }
                          _html += '<tr><td style="padding: 0px;">'
                          _html += '<button type="button" class="btn btn-sm" style="color:#0A7389; font-size: medium;" onclick="openCustomerSummaryTab(\'' + item.id + '\')"">' + item.name + '</button></td>';
                          _html += '<td style="padding: 0px;"><spa>' + (item.age ?? "") + '</span></td>';
                          _html += '<td style="padding: 0px;"><span>';
                          if (item.twoLetterCountryCode != null) {
                            _html += '<img class="img-thumbnail" width="25" src="https://s3.stellamedi.com/stellamedi-cdn/images/country-flags/' + item.twoLetterCountryCode.toLowerCase() + '.png" style="height:calc(0.7em + 0.8rem + 1px);" />';
                          }
                          _html += '</span></td> ';
                          if (index == this.customers.length - 1) {
                            _html += "</table></div></div>";
                          }
                        });
                      }
                      _html += "<hr/>";
                      $this.$swal({
                        title: alertTitle,
                        html: _html,
                        type: ($this.$attrs.allowDublicateNumber == 0 ? "error" : "warning"),
                        buttonsStyling: false,
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: cancelButton,
                        cancelButtonClass: "btn btn-primary btn-elevate btn-sm",
                        customClass: {
                          popup: 'custom-modal'
                        }
                      });
                    }
                  }
                  else {
                    if ($this.objectId && $this.oldNumber.replace(/\s/g, '') != $this.value.internationalFormat.replace(/\s/g, '') && $this.oldNumber.replace(/\s/g, '') != $this.value.phone)
                      $this.value.phoneNumberIsExists = false;
                  }
                });
            }
          }
          else {
            $this.phoneObject = {};
            $this.isValid = false;
          }
        }
      },
      value: {
        handler: function (val) {
          if (val) {
            this.oldNumber = val.internationalFormat;
            this.phoneNumber = this.value;
          }
        },
        deep: true
      }
    },
    computed: {
      currentCountryCode() {
        return 'TR';
        //return (this.$store.getters.tenantInfo.countryCode).toString().toUpperCase();
      },
      flagPath() {
        let cdnUrl = "";
        if (this.phoneObject && this.phoneObject.country) {
          cdnUrl = "https://s3.stellamedi.com/stellamedi-cdn/images/country-flags/" + this.phoneObject.country.toLowerCase() + ".png";
        }
        else {
          cdnUrl = "https://s3.stellamedi.com/stellamedi-cdn/images/country-flags/zz.png";
        }
        return cdnUrl;
      }
    }
  }
</script>

<style>
  .custom-modal {
    width: 550px !important;
    height: auto !important;
  }
</style>
